.Client {
  .WaitingPage {
    height: var(--height);
    background-color: white;

    &__title {
      @extend .title, .title--blue;
    }

    &__text {
      @extend .text;
    }

    &__image {
      margin-top: 50px;
      width: 100%;
    }
  }
}
