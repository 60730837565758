.Client {
  .InstructionsPage {
    @extend .flex, .flex--center, .flex--column;
    justify-content: space-evenly;
    position: relative;
    width: 100vw;
    overflow-y: scroll;
    padding: 65px 0 0 0;
    gap: 35px;

    &__title {
      @extend .font-bell-heavy;
      font-size: 40px;
      margin-bottom: 5px;

      @media screen and (min-width: 600px) {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }

    &__content {
      @extend .font-bell-heavy;
      width: 90vw;

      position: relative;
      text-align: center;
      font-size: 25px;
      line-height: 27px;

      @media screen and (min-width: 600px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    &__image {
      width: 85vw;
    }

    &__decorations {
      width: 100vw;
      padding-right: 10px;
    }
  }
}
