.Client {
  .SorryPage {
    @extend .flex, .flex--center, .flex--middle, .flex--column;
    width: 100vw;
    height: var(--height);
    overflow: hidden;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;

    &__title {
      @extend .title;
      line-height: 32px;
      max-width: 340px;
      padding: 5px 0;

      @media screen and (min-width: 600px) {
        font-size: 3rem;
        line-height: 3.5rem;
        max-width: 500px;
      }
    }

    &__bottom {
      height: 50%;
      padding-right: 30px;
      margin-top: 30px;

      @media screen and (min-width: 600px) {
        height: 34%;
        margin-top: 70px;
        padding-right: 10px;
      }
    }

    &__sub-title {
      @extend .sub-title;
      line-height: 32px;
      padding-bottom: 30px;
      max-width: 340px;
      padding: 5px 0;

      @media screen and (min-width: 600px) {
        font-size: 2.5rem;
        line-height: 2.5rem;
        max-width: 500px;
      }
    }

    &__decoration {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      object-position: top;
      bottom: 0;
      margin-top: 0px;
      position: relative;

      max-width: 100%;

      @media screen and (min-width: 768px) {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
