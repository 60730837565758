.Page {
  // overflow-y: hidden;
  //position: relative;

  // @media screen and (min-height: 800px){
  //   justify-content: center;
  // }
  // @media screen and (orientation: landscape) and (min-width: 800px) {
  //   justify-content: center;
  // }

  &__content {
    @extend .flex, .flex--center, .flex--middle, .flex--column;
  }

  &__bg {
    // position: absolute;

    &--top {
      top: 0;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--bottom {
      bottom: 0;
    }
  }
}
