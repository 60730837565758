.SignupImagePage {
  @extend .flex, .flex--center, .flex--middle, .flex--column;
  position: relative;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  height: var(--height);
  width: 100vw;

  &__avatarSelection {
    //background: linear-gradient(135deg, rgba(3, 106, 167, 1) 0%,rgba(0, 156, 212, 1) 100% );
    width: 100vw;
    height: var(--height);
  }

  &__header {
    @extend .font-bell-heavy;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #2689c4;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    z-index: 1;

    @media screen and (min-width: 600px) {
      padding: 2rem 0;
      font-size: 3rem;
    }
  }

  &__headerAvatar {
    @extend .font-bell-heavy;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #2689c4;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    z-index: 1;

    @media screen and (min-width: 600px) {
      height: 12%;
      font-size: 3rem;
    }
  }

  &__headerAvatar_flex {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    bottom: 0;
    background-color: #000000;
    height: 56px;
    width: 100vw;
    font-size: 30px;
    z-index: 1;

    @media screen and (min-width: 600px) {
      height: 30px;
    }
  }

  &__mask {
    height: 60%;
    -webkit-mask-image: radial-gradient(
      90vw at 50% 50%,
      #000000 50%,
      rgba(0, 0, 0, 0.5) 40%
    );
    mask-image: radial-gradient(
      90vw at 50% 50%,
      #000000 50%,
      rgba(0, 0, 0, 0.5) 50%
    );

    @media screen and (min-width: 600px) {
      mask-position: center;
      -webkit-mask-image: radial-gradient(
        60vw at 50% 50%,
        #000000 50%,
        rgba(0, 0, 0, 0.5) 40%
      );
      mask-image: radial-gradient(
        60vw at 50% 50%,
        #000000 50%,
        rgba(0, 0, 0, 0.5) 50%
      );
    }
  }
  &__video {
    object-fit: cover;
    height: 100%;

    @media screen and (min-width: 600px) {
      width: 100%;
      object-fit: fill;
      //TODO: here is a bug, the video is not filling the screen
    }
  }

  &__selfie {
    @extend .flex, .flex--center, .flex--middle, .flex--column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 60%;
    width: 100vw;
  }

  &__selfieMask {
    -webkit-mask-image: radial-gradient(
      95vw at 50% 50%,
      #fffefe 50%,
      #00000000 40%
    );
    mask-image: radial-gradient(95vw at 50% 50%, #fffefe 50%, #00000000 40%);

    @media screen and (min-width: 600px) {
      -webkit-mask-image: radial-gradient(
        65vw at 50% 50%,
        #fffefe 50%,
        #00000000 40%
      );
      mask-image: radial-gradient(60vw at 50% 50%, #fffefe 50%, #00000000 40%);
    }
  }

  &__image {
    transform: translate(0, -100px);
    z-index: 1;
  }

  &__container {
    bottom: 0;
    height: 30%;
    z-index: 100;
    width: 100%;
    padding-top: 20px;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__imagecontainer {
    height: 30%;
    top: 1;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media screen and (min-width: 600px) {
      height: 28%;
    }
  }

  &__selfieButtons {
    width: 75%;
  }

  &__buttons {
    @extend .flex, .flex--center;
    width: 90%;

    @media screen and (min-width: 600px) {
      margin-bottom: 10px;
      width: auto;
    }
  }

  &__profilePicture {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__avatarGrid {
    align-items: center;
    overflow-x: scroll;
    display: flex;
    flex-wrap: wrap;
    height: 90%;
  }

  &__gridItem {
    padding: 20px;
    width: 33%;
    font-size: 30px;
    align-items: center;
    z-index: 2;
  }

  &__error {
    @extend .font-bell-heavy;
    text-align: center;
    position: absolute;
    align-items: center;
    font-size: 40px;
  }

  &__imageAvatar {
    padding: 10px;
    width: 90vw;

    @media screen and (min-width: 600px) {
      max-width: 70vw;
      width: 60vw;
    }
  }
}
