.SignupInitialsPage {
  @extend .flex, .flex--center, .flex--middle, .flex--column;
  width: 100vw;
  height: var(--height);

  &_top {
  }

  &_bottom {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  &__text {
    @extend .font-bell-heavy;
    max-width: 360px;
    font-size: 32px;
    text-align: center;
    padding-bottom: 15px;
    margin: 0 2rem;

    @media screen and (min-width: 600px) {
      max-width: 700px;
      font-size: 2rem;
      padding-bottom: 0.8rem;
    }
  }

  &__form {
    @extend .flex, .flex--center, .flex--middle, .flex--column;
    z-index: 1;
    // width: 100%;
  }

  &__decoration-image {
    @extend .Page__bg, .Page__bg--top, .Page__bg--right;
    max-width: 35vw;
    position: absolute;
    width: 140px;
    z-index: -1;

    @media screen and (min-width: 600px) {
      max-width: 50vw;
      width: 200px;
    }
  }

  &__login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 70px;

    &-password {
      @extend .font-bell-heavy;
      box-sizing: border-box;
      height: 56px;
      width: 80vw;
      border: 2px solid #0366a4;
      border-radius: 28px;
      background-color: #ffffff;
      color: #000000;
      font-size: 17px;
      text-align: center;
      outline: none;
      margin-bottom: 16px;

      &:focus::-webkit-input-placeholder {
        opacity: 0;
      }

      @media screen and (min-width: 600px) {
        max-width: 30rem;
        height: 3.5rem;
        border-radius: 50px;
        font-size: 1.5rem;
        padding: 10px 0;
        margin: 0 auto 1.5rem;
      }
    }

    &-dropdown {
      @extend .font-bell-heavy;

      position: relative;

      display: grid;
      grid-template-areas: "select";
      margin: 0 auto 2rem;
      width: 80vw;
      height: 56px;
      align-items: center;
      border: 2px solid #0366a4;
      border-radius: 28px;
      background-color: #ffffff;
      font-size: 24px;

      select,
      &::after {
        grid-area: select;
      }

      select {
        @extend .font-bell-heavy;
        appearance: none;
        box-sizing: border-box;
        border: none;
        background: none;
        height: 56px;

        color: #000000;

        font-size: 24px;
        text-align: center;
        outline: none;
        margin-bottom: 16px;
        padding: 0 1em 0 0;

        margin: 0;
        width: 100%;
      }

      &::after {
        content: "";
        // position: absolute;

        justify-self: end;
        width: 0.8em;
        height: 0.5em;
        background-color: #0366a4;
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        margin-right: 15px;
      }

      &:focus::-webkit-input-placeholder {
        opacity: 0;
      }

      @media screen and (min-width: 600px) {
        height: 3.5rem;
        border-radius: 50px;
        max-width: 30rem;

        select {
          font-size: 1.5rem;
          padding-bottom: 5px;
          // padding: 10px 0;
          // margin: 0 auto 2rem;
        }
      }
    }
  }

  &__animation {
    &--downFade {
      animation: downFade 0.75s ease;
    }

    &--upFade {
      animation: upFade 0.75s ease;
    }

    @keyframes downFade {
      from {
        opacity: 0;
        transform: translateY(-50vh);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes upFade {
      from {
        opacity: 0;
        transform: translateY(50vh);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.Signup__error {
  @extend .font-bell-heavy;
  color: #ffa600;
  font-size: 1rem;
  text-align: center;
  margin: 0 2rem 1rem;
  z-index: 1;

  @media screen and (min-width: 600px) {
    font-size: 1rem;
    padding: 0 3rem;
    margin-bottom: 1rem;
  }
}
