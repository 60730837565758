.Client {
  &.GamePage {
    .Page__content {
    }
  }

  .GamePage {
    &__container {
      @extend .flex, .flex--column, .flex--center, .form;
      height: 100%;
      color: #fff;
      text-align: center;
      padding-top: 10vh;

      @media screen and (max-width: 320px) {
        padding-top: 6vh;
      }

      @media screen and (min-width: 600px) {
        padding-top: 3vh;
      }
    }

    &__top {
      @media screen and (min-width: 600px) {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 3rem;
        width: 100%;
      }
    }

    &__header {
      @extend .font-bell-heavy;

      font-size: 30px;
      padding-bottom: 10px;

      @media screen and (min-width: 600px) {
        font-size: 50px;
        line-height: 50px;
      }
    }

    &__timer {
      // placholder
      @media screen and (min-width: 600px) {
        padding: 1rem 0;
      }
    }

    &__word {
      @extend .font-bell-heavy;
      font-size: 60px;

      @media screen and (max-width: 320px) {
        font-size: 50px;
        padding: 0.5rem;
      }
      @media screen and (min-width: 600px) {
        font-size: 4rem;
        padding-top: 0;
      }
    }

    &__form {
      &-input {
        @extend .font-bell-heavy;
        @extend .form__input;
        font-size: 30px;
        color: #0366a4;

        @media screen and (max-width: 320px) {
          font-size: 25px;
          width: 280px;
        }
        @media screen and (min-width: 600px) {
          font-size: 1.8rem;
          width: 450px;
          height: 65px;
          border-radius: 50px;
          margin: 0.5rem 0;
          padding: 10px 0;
        }
      }
    }

    &__attempts {
      @extend .flex, .flex--center, .flex--middle;
      max-width: 90vw;
      flex-wrap: wrap;
      gap: 15px;

      @media screen and (min-width: 600px) {
        max-width: 80vw;
        gap: 10px;
      }
      .attempt {
        padding: 5px 15px;
        font-size: 20px;
        border-radius: 20px;

        @media screen and (min-width: 600px) {
          font-size: 1.5rem;
          padding: 0.5rem 1.5rem;
          gap: 0.5rem;
        }

        &--correct {
          background-color: #0eb800;
        }

        &--incorrect {
          background-color: #ff3a3a;
        }
      }
    }
  }
}
