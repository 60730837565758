.button {
  @extend .font-bell-heavy;
  max-width: 360px;
  width: 100%;
  border-radius: 28px;
  background-color: #0366a4;
  color: #ffffff;

  text-align: center;
  @media only screen and (max-width: 600px) {
    body {
      max-width: 100vw;
    }
  }

  border: none;
  cursor: pointer;

  &:hover {
    background-color: #023778c7;
  }

  &:disabled {
    background-color: #b3b3b3;
    pointer-events: none;
  }

  &--transparent {
    background-color: transparent;
    color: #0366a4;

    &:hover {
      background-color: transparent;
    }
  }

  &__icon {
    max-width: 130px;
    line-height: 20px;

    .icon {
      float: right;
      display: inline;
      margin: 2.5px 5px;
      width: 15px;
      height: 15px;
    }
  }

  &--short {
    height: 30px;
    font-size: 15px;
    line-height: 18px;
  }

  &--long {
    @extend .font-bell-heavy;
    height: 53px;
    width: 80vw;
    font-size: 28px;
    padding-bottom: 2px;
    font-weight: normal;

    @media screen and (min-width: 600px) {
      font-size: 2.5rem;
      height: 5rem;
      max-width: 30rem;
      border-radius: 50px;
      padding: 10px 0;
    }
  }

  &--red {
    background-color: #780202;
  }

  &--green {
    background-color: #24a403;
  }
}

.captureButton {
  padding-bottom: 21px;
  margin-top: 21px;
  width: 65px;
  height: 65px;
  background-color: #2196f3;
  border-radius: 50%;
  place-items: center;
  padding: 0 2em;
  border: 5px solid #ffffff;

  @media screen and (min-width: 600px) {
    height: 90px;
    width: 90px;
    padding-top: 30px;
  }
}

.choiceButton {
  @extend .button;
  @extend .font-bell-heavy;

  height: 36px;
  width: 184px;
  text-align: center;
  font-size: 16px;

  @media screen and (min-width: 600px) {
    font-size: 1.6rem;
    height: 72px;
    width: 300px;
    border-radius: 50px;
  }

  &--transparent {
    background-color: transparent;
    color: #ffffff;
  }

  &:hover {
    background-color: transparent;
  }

  &--validate {
    @extend .font-bell-heavy;
    background-color: #f4f4f4;
    max-width: 100%;
    padding: 11px 0 11px 0;
    color: #0366a4;
    font-size: 28px;
    margin-bottom: 20px !important;
  }
  &--startOver {
    @extend .font-bell-heavy;
    max-width: 100%;
    padding: 11px 0 11px 0;
    color: #ffffff;
    font-size: 28px;
    &:hover {
      background-color: #2196f3;
    }
  }
}
