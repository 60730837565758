@import "./fonts.scss";
@import "./buttons.scss";
@import "./helpers.scss";
@import "./page.scss";
@import "./modal.scss";
@import "./animations.scss";
@import "./toolbar.scss";
@import "./timer.scss";
@import "./admin/admin.scss";
@import "./client/client.scss";
@import "./client/survey.scss";
@import "./client/info.scss";
@import "./client/instructions.scss";

:root  {
  color-scheme: only light;
  --lightshow-color: #0366a4;
  --height: 100%;
}

body {
  height: 100vh;
  height: var(--height);
  margin: 0;
  // overflow: hidden;
}
html {
  margin: 0;
  padding: 0;
  font-family: Helvetica;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fff;
  color: #000;

  @media (prefers-color-scheme: dark) {
    background-color: #fff;
    color: #000;
  }
}

// reset styles
* {
  box-sizing: border-box;
  overscroll-behavior: none;
}

// loading icon
.loading {
  max-height: 100%;
}
