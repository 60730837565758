.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  p {
    margin: 0;
    padding: 0;
  }

  h4 {
    color: #000000;
    font-family: Helvetica;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  &>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 226px;
    width: 100%;
    height: auto;

  }

  &__current {
    font-size: 22px;
    line-height: 32px;

    strong {
      font-size: 24px;
      line-height: 34px;      
      text-align: center;
      padding: 0;
      font-family: "bell_slim_heavy";
      color: #0068a9;
      font-weight: 500;
      margin-right: 8px;
    }

  }

  .actions__current {
    flex-direction: row;
    max-width: 300px;
  }

  hr {
    margin: 0;
  }

  &__logo {
    font-family: "bell_slim_heavy";
    max-width: 120px;
    margin: 20px auto 10px auto;

    @media screen and (min-width: 768px) {
      max-width: 180px;
    }

  }

  &-inside {
    margin-top: 150px;
  }

  .button--long {
    width: 60vw;
    height: 40px;
    font-size: 20px;
    margin-bottom: 10px;
    border-radius: 36px;
    @media screen and (min-width: 768px) {
      font-size: 22px;
      height: 50px;
    }
  }
}

.stats {
  margin-bottom: 30px;

  h1 {
    width: 100%;
    background-color: #282C34;
    margin: 0;
    color: #FFFFFF;
    font-family: Helvetica;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    padding: 15px 0;
    margin-bottom: 30px;
  }

  &-stat-box {
    &-onebyone__stat {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 13px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__title__stat {
        color: #000000;
        font-family: Helvetica;
        font-size: 17px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        font-weight: 700;
        margin: 0;

        &.current {
          margin-bottom: 13px;
        }

      }

      &__result__stat {
        color: #000000;
        font-family: Helvetica;
        font-size: 17px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 0;
      }
    }
  }
}