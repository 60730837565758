.flex {
  display: flex;

  &--center {
    align-items: center;
  }

  &--middle {
    justify-content: center;
  }

  &--column {
    flex-flow: column;
  }

  &--row {
    flex-flow: row;
  }
}

// Screen overlay helpers
.full-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;

  &--overlay {
    background-color: rgba(190, 190, 190, 0.07);
    pointer-events: none;
  }
}

// Gradient background
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg {
  background: linear-gradient(180deg, #009cd4, #036aa7, #23a6d5, #0366a4);
  background-size: 400% 400%;
  animation: gradient;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

// Lightshow helpers
@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.lightshow {
  @extend .full-window;

  // background-color: #0366A4; // blue
  // background-color: #b9975b; // gold
  background-color: var(--lightshow-color);
  animation: blinker;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

// TO REVIEW: High hack to fill screen hight
.full-height {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  pointer-events: none;

  // @media screen and (orientation: landscape) {
  //   height: 700px;
  // }
}

// title

.title {
  @extend .font-bell-heavy;

  user-select: none;

  font-size: 32px;
  font-weight: 500;

  line-height: 34px;
  text-align: center;

  margin-bottom: 3vh;

  @media screen and (max-width: 320px) {
    font-size: 22px;
    line-height: 24px;
  }

  &--blue {
    color: #0068a7;
  }
}

.sub-title {
  @extend .font-bell-medium;

  user-select: none;

  font-size: 31px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;

  @media screen and (max-width: 320px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
  }
}

.text {
  user-select: none;

  font-size: 21px;
  line-height: 25px;
  text-align: center;

  @media screen and (max-width: 320px) {
    font-size: 16px;
    line-height: 20px;
  }

  &--bold {
    font-weight: bold;
  }
}

.form {
  &__input {
    box-sizing: border-box;
    height: 56px;
    width: 323px;
    border: 2px solid #0366a4;
    border-radius: 28px;
    background-color: #ffffff;
    color: #828282;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    outline: none;
    margin-bottom: 16px;

    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }
  }
}
